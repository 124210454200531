<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="localDate"
    persistent
    width="290px"
  >
   <template v-slot:activator="{ on }">
    <v-text-field
      v-model="computedDateFormat"
      :label="label"
      prepend-icon="mdi-calendar"
      :disabled="edit"
      readonly
      v-on="on"
      :class="classField"
      :rules="rules"
    ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="localDate"
      scrollable
      locale="es"
      :readonly="edit"
      :min="min"
      :max="max"
    >
      <v-spacer></v-spacer>
      <v-btn text color="error" @click="modal = false">Cancelar</v-btn>
      <v-btn text color="primary lighten-3" @click="cambioFecha">OK</v-btn>
    </v-date-picker>
  </v-dialog>
</template>
<script>
export default {
  props: {
    date: {
      type: String,
      default: new Date().toISOString().substr(0, 10)
    },
    label: String,
    propiedad: String,
    min: {
      type: String,
      default: "1930-01-01"
    },
    max: {
      type: String,
      default: new Date().toISOString().substr(0, 10)
    },
    birthDay: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    classField:{
      type:String,
      default:''
    },
    rules:{
      type:Array,
      default:()=>{
        []
      }
    }
  },
  data() {
    return {
      modal: false
    };
  },
  watch: {
    modal(val) {
      if (this.birthDay) {
        val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
      }
    }
  },
  computed: {
    localDate: {
      get: function() {
        return this.date;
      },
      set: function(data) {
        this.$emit("cambiarProp", {
          campo: this.propiedad,
          valor: data
        });
      }
    },
    computedDateFormat: {
      get: function() {
        return this.formatDateText(this.localDate);
      },
      set: function(data) {
        this.$emit("cambiarProp", {
          campo: this.propiedad,
          valor: data
        });
      }
    }
  },
  methods: {
    cambioFecha() {
      this.$refs.dialog.save(this.localDate);
      this.$emit("cambiarProp", {
        campo: this.propiedad,
        valor: this.localDate
      });
    },
     formatDateText(date) {
      if (!date) return null;
      const [dates, hours] = date.split(" ");
      if (date.indexOf("-") > 0) {
        const [year, month, day] = dates.split("-");
        return `${day}/${month}/${year}`;
      }
      return date;
    },
  }
};
</script>
